<template>
    <div>
        <!--  绩效的分组-->
        <el-select @change="changeFn" clearable placeholder="选择分组" v-model="options">
            <el-option :key="index" :label="item.label" :value="item.value" v-for="(item,index) in currentGroupId">
                <span style="float: left"> {{ item.label||item.name }} </span>
                <span style="float: right"> {{ item.value || item.id }} </span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
  export default {
    name: 'PerformanceGroupSelect',
    props: {
      groupId: {
        type: [Number, String],
        default() {
          return null
        }
      },
      searchCondition: {
        type: Object,
        default() {
          return { year: null, quarter: null }
        }
      },
      currentGroupId: {
        type: Array
      }
    },

    data() {
      return {
        options: ''
      }
    },
    methods: {
      changeFn() {
        this.$emit('update:groupId', this.options)
        this.$emit('change')
      }
    }
  }
</script>

<style scoped>

</style>
